import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Switch,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormControlLabel from "@mui/material/FormControlLabel";

const AddOrderDialog = ({
  isOpen,
  onClose,
  onSubmit,
  users,
  shippingAddresses,
  promoCodes,
  products,
}) => {
  const [newOrderData, setNewOrderData] = useState({
    user_id: "",
    shipping_address_id: "",
    promocode_id: "",
    is_paid: "",
    status: "",
    selectedProducts: [],
  });
  const [productQuantities, setProductQuantities] = useState({});
  const [highlightedProducts, setHighlightedProducts] = useState([]);
  const [useUserPoints, setUseUserPoints] = useState(false);
  const [deliveryFees, setDeliveryFees] = useState(true);
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [newAddress, setNewAddress] = useState({
    first_name: "",
    last_name: "",
    address: "",
    phone: "",
    selectedCountry: "",
    selectedCity: "",
    email: "",
  });
  const [countryOptions] = useState([{ value: "SA", label: "Saudi Arabia" }]);
  const cityOptions = [
    { value: "Abha", label: "Abha" },
    { value: "Ad Dilam", label: "Ad Dilam" },
    { value: "Ad Diriyah", label: "Ad Diriyah" },
    { value: "Afif", label: "Afif" },
    { value: "Ahad Al Musarihah", label: "Ahad Al Musarihah" },
    { value: "Ahad Rafidah", label: "Ahad Rafidah" },
    { value: "Al Aflaj (Layla)", label: "Al Aflaj (Layla)" },
    { value: "Al Badai", label: "Al Badai" },
    { value: "Al Bashayer", label: "Al Bashayer" },
    { value: "Al Baha", label: "Al Baha" },
    { value: "Al Bukayriyah", label: "Al Bukayriyah" },
    { value: "Al Ghat", label: "Al Ghat" },
    { value: "Al Hariq", label: "Al Hariq" },
    { value: "Al Hofuf", label: "Al Hofuf" },
    { value: "Al Jubail", label: "Al Jubail" },
    { value: "Al Jumum", label: "Al Jumum" },
    { value: "Al Kharj", label: "Al Kharj" },
    { value: "Al Khafji", label: "Al Khafji" },
    { value: "Al Khubar", label: "Al Khubar" },
    { value: "Al Khurmah", label: "Al Khurmah" },
    { value: "Al Majardah", label: "Al Majardah" },
    { value: "Al Majma'ah", label: "Al Majma'ah" },
    { value: "Al Midhnab", label: "Al Midhnab" },
    { value: "Al Muzahimiyah", label: "Al Muzahimiyah" },
    { value: "Al Qatif", label: "Al Qatif" },
    { value: "Al Qurayyat", label: "Al Qurayyat" },
    { value: "Al Wajh", label: "Al Wajh" },
    { value: "Al-Dawadmi", label: "Al-Dawadmi" },
    { value: "An Nuayriyah", label: "An Nuayriyah" },
    { value: "Ar Rass", label: "Ar Rass" },
    { value: "Arar", label: "Arar" },
    { value: "As Sulayyil", label: "As Sulayyil" },
    { value: "Ash Shinan", label: "Ash Shinan" },
    { value: "Ash Shimasiyah", label: "Ash Shimasiyah" },
    { value: "Aqiq", label: "Aqiq" },
    { value: "Az Zulfi", label: "Az Zulfi" },
    { value: "Badr", label: "Badr" },
    { value: "Billasmar", label: "Billasmar" },
    { value: "Bishah", label: "Bishah" },
    { value: "Buqayq", label: "Buqayq" },
    { value: "Buraydah", label: "Buraydah" },
    { value: "Dammam", label: "Dammam" },
    { value: "Dawmat Al Jandal", label: "Dawmat Al Jandal" },
    { value: "Dhahran", label: "Dhahran" },
    { value: "Dhurma", label: "Dhurma" },
    { value: "Duba", label: "Duba" },
    { value: "Hafar al-Batin", label: "Hafar al-Batin" },
    { value: "Haqil", label: "Haqil" },
    { value: "Hail", label: "Hail" },
    { value: "Hawtat Bani Tamim", label: "Hawtat Bani Tamim" },
    { value: "Inak", label: "Inak" },
    { value: "Jazan", label: "Jazan" },
    { value: "Jeddah", label: "Jeddah" },
    { value: "Jouf", label: "Jouf" },
    { value: "Khamis Mushait", label: "Khamis Mushait" },
    { value: "Khaybar", label: "Khaybar" },
    { value: "Khulais", label: "Khulais" },
    {
      value: "King Abdullah Economic City",
      label: "King Abdullah Economic City",
    },
    { value: "Laith", label: "Laith" },
    { value: "Layla", label: "Layla" },
    { value: "Madinah", label: "Madinah" },
    { value: "Makkah", label: "Makkah" },
    { value: "Muhayil Aseer", label: "Muhayil Aseer" },
    { value: "Mudhaylif", label: "Mudhaylif" },
    { value: "Najran", label: "Najran" },
    { value: "Qaryat Al Ulya", label: "Qaryat Al Ulya" },
    { value: "Qassim", label: "Qassim" },
    { value: "Quwayiyah", label: "Quwayiyah" },
    { value: "Rafha", label: "Rafha" },
    { value: "Rania", label: "Rania" },
    { value: "Ras Tannurah", label: "Ras Tannurah" },
    { value: "Riyad Al Khabra", label: "Riyad Al Khabra" },
    { value: "Riyadh", label: "Riyadh" },
    { value: "Rabigh", label: "Rabigh" },
    { value: "Sabya", label: "Sabya" },
    { value: "Safwa", label: "Safwa" },
    { value: "Samtah", label: "Samtah" },
    { value: "Sayhat", label: "Sayhat" },
    { value: "Sharurah", label: "Sharurah" },
    { value: "Shaqra", label: "Shaqra" },
    { value: "Shraiee", label: "Shraiee" },
    { value: "Sakaka", label: "Sakaka" },
    { value: "Tabuk", label: "Tabuk" },
    { value: "Taif", label: "Taif" },
    { value: "Tathilith", label: "Tathilith" },
    { value: "Tarut", label: "Tarut" },
    { value: "Thadiq", label: "Thadiq" },
    { value: "Thuwal", label: "Thuwal" },
    { value: "Turaif", label: "Turaif" },
    { value: "Turaib", label: "Turaib" },
    { value: "Turbah", label: "Turbah" },
    { value: "Umluj", label: "Umluj" },
    { value: "Unaizah", label: "Unaizah" },
    { value: "Uqlat Al Suqur", label: "Uqlat Al Suqur" },
    { value: "Wadi El Dwaser", label: "Wadi El Dwaser" },
    { value: "Yanbu", label: "Yanbu" },
    { value: "Zalal", label: "Zalal" },
  ];

  const [errors, setErrors] = useState({
    user_id: false,
    shipping_address_id: false,
    is_paid: false,
    status: false,
    selectedProducts: false,
    useUserPoints: false,
  });

  useEffect(() => {
    if (isOpen) {
      setNewOrderData({
        user_id: "",
        shipping_address_id: "",
        promocode_id: "",
        is_paid: "",
        status: "",
        selectedProducts: [],
      });
      setProductQuantities({});
      setHighlightedProducts([]);
      setShowNewAddressForm(false);
      setErrors({
        user_id: false,
        shipping_address_id: false,
        is_paid: false,
        status: false,
        selectedProducts: false,
      });
    }
  }, [isOpen]);

  const handleChange = (field, value) => {
    setNewOrderData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: false,
    }));
  };

  const handleProductChange = (selectedProducts) => {
    setNewOrderData((prevData) => ({
      ...prevData,
      selectedProducts,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      selectedProducts: false,
    }));
  };

  const handleQuantityChange = (productId, quantity) => {
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
  };

  const handleProductMenuOpen = () => {
    setHighlightedProducts(newOrderData.selectedProducts);
  };

  const handleProductMenuItemMouseEnter = (productId) => {
    if (!highlightedProducts.includes(productId)) {
      setHighlightedProducts((prevHighlighted) => [
        ...prevHighlighted,
        productId,
      ]);
    }
  };

  const handleProductMenuItemMouseLeave = (productId) => {
    if (!newOrderData.selectedProducts.includes(productId)) {
      setHighlightedProducts((prevHighlighted) =>
        prevHighlighted.filter((id) => id !== productId)
      );
    }
  };

  const handleCountryChange = (selectedOption) => {
    setNewAddress({
      ...newAddress,
      selectedCountry: selectedOption.target.value,
    });
  };

  const handleCityChange = (selectedOption) => {
    console.log("newAddress", newAddress);
    setNewAddress({
      ...newAddress,
      selectedCity: selectedOption.target.value,
    });
  };

  const handleSubmit = () => {
    let hasErrors = false;
    const newErrors = {};

    Object.keys(newOrderData).forEach((field) => {
      if (
        !newOrderData[field] &&
        field !== "promocode_id" &&
        field !== "selectedProducts" &&
        !(field === "shipping_address_id" && showNewAddressForm)
      ) {
        hasErrors = true;
        newErrors[field] = true;
      }
    });

    if (newOrderData.selectedProducts.length === 0) {
      hasErrors = true;
      newErrors.selectedProducts = true;
    }

    // Additional validation for new address fields if the form is shown
    if (showNewAddressForm) {
      if (!newAddress.first_name) {
        hasErrors = true;
        newErrors.newAddressFirstName = true;
      }
      if (!newAddress.last_name) {
        hasErrors = true;
        newErrors.newAddressLastName = true;
      }
      if (!newAddress.address) {
        hasErrors = true;
        newErrors.newAddress = true;
      }
      if (!newAddress.selectedCity) {
        hasErrors = true;
        newErrors.selectedCity = true;
      }
      if (!newAddress.selectedCountry) {
        hasErrors = true;
        newErrors.selectedCountry = true;
      }
      if (!newAddress.phone) {
        hasErrors = true;
        newErrors.newAddressPhone = true;
      }
    }

    if (hasErrors) {
      setErrors(newErrors);
    } else {
      const orderDataWithQuantities = {
        ...newOrderData,
        productQuantities,
        useUserPoints,
        deliveryFees,
        newAddress: showNewAddressForm ? newAddress : null,
      };
      console.log("deliveryFees", orderDataWithQuantities);
      onSubmit(orderDataWithQuantities);
      onClose();
      setNewOrderData({
        user_id: "",
        shipping_address_id: "",
        promocode_id: "",
        is_paid: "",
        status: "",
        selectedProducts: [],
      });
      setProductQuantities({});
      setHighlightedProducts([]);
      setErrors({
        user_id: false,
        shipping_address_id: false,
        is_paid: false,
        status: false,
        selectedProducts: false,
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
          error={errors.user_id}
        >
          <InputLabel>User *</InputLabel>
          <Select
            value={newOrderData.user_id}
            onChange={(e) => handleChange("user_id", e.target.value)}
            label="Select User"
          >
            <MenuItem value={"guest"}> Guest </MenuItem>

            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.first_name} {user.last_name} {user.phone}
              </MenuItem>
            ))}
          </Select>
          {errors.user_id && (
            <FormHelperText style={{ color: "red" }}>Required</FormHelperText>
          )}
        </FormControl>

        {newOrderData.user_id && newOrderData.user_id !== "guest" && (
          <FormControl fullWidth margin="normal" variant="outlined">
            <FormControlLabel
              control={
                <Switch
                  checked={useUserPoints}
                  onChange={() => setUseUserPoints(!useUserPoints)}
                  color="success"
                />
              }
              label="Use User Points"
              labelPlacement="start"
              style={{ marginLeft: 0, marginRight: "auto" }}
            />
          </FormControl>
        )}

        <div style={{ display: "flex", alignItems: "center" }}>
          {!showNewAddressForm ? (
            <>
              <FormControl
                fullWidth
                margin="normal"
                variant="outlined"
                error={errors.shipping_address_id}
              >
                <InputLabel>Shipping Address *</InputLabel>
                <Select
                  value={newOrderData.shipping_address_id}
                  onChange={(e) =>
                    handleChange("shipping_address_id", e.target.value)
                  }
                  label="Select Shipping Address"
                >
                  {shippingAddresses.map((address) => (
                    <MenuItem key={address.id} value={address.id}>
                      {address.first_name} {address.last_name} -{" "}
                      {address.address} - {address.phone}
                    </MenuItem>
                  ))}
                </Select>
                {errors.shipping_address_id && (
                  <FormHelperText style={{ color: "red" }}>
                    Required
                  </FormHelperText>
                )}
              </FormControl>
              <IconButton
                onClick={() => setShowNewAddressForm(true)}
                style={{ marginLeft: 8 }}
              >
                <AddIcon />
              </IconButton>
            </>
          ) : (
            <div style={{ width: "100%" }}>
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="First Name"
                value={newAddress.first_name}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, first_name: e.target.value })
                }
                required
              />
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="Last Name"
                value={newAddress.last_name}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, last_name: e.target.value })
                }
                required
              />
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Select Country</InputLabel>

                <Select
                  value={newAddress.selectedCountry}
                  onChange={handleCountryChange}
                  label="Select Country"
                >
                  {countryOptions.map((country) => (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.selectedProducts && (
                  <FormHelperText style={{ color: "red" }}>
                    Required
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Select City</InputLabel>
                <Select
                  value={newAddress.selectedCity}
                  onChange={handleCityChange}
                  label="Select City"
                  disabled={!newAddress.selectedCountry}
                >
                  {cityOptions.map((city) => (
                    <MenuItem key={city.value} value={city.value}>
                      {city.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="Address"
                value={newAddress.address}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, address: e.target.value })
                }
                required
              />
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="Phone"
                value={newAddress.phone}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, phone: e.target.value })
                }
                required
              />
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                label="Email"
                value={newAddress.email}
                onChange={(e) =>
                  setNewAddress({ ...newAddress, email: e.target.value })
                }
              />
              <Button
                onClick={() => setShowNewAddressForm(false)}
                variant="outlined"
                style={{ marginTop: 8 }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>

        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
          error={errors.selectedProducts}
        >
          <InputLabel>Products *</InputLabel>
          <Select
            multiple
            value={newOrderData.selectedProducts}
            onChange={(e) => handleProductChange(e.target.value)}
            onOpen={handleProductMenuOpen}
            label="Select Products"
          >
            {products.map((product) => (
              <MenuItem
                key={product.id}
                value={product.id}
                onMouseEnter={() => handleProductMenuItemMouseEnter(product.id)}
                onMouseLeave={() => handleProductMenuItemMouseLeave(product.id)}
                style={{
                  backgroundColor: highlightedProducts.includes(product.id)
                    ? "rgba(169, 169, 169, 0.5)"
                    : "transparent",
                }}
              >
                {product.name_en} {product.price} SAR
              </MenuItem>
            ))}
          </Select>
          {errors.selectedProducts && (
            <FormHelperText style={{ color: "red" }}>Required</FormHelperText>
          )}
        </FormControl>

        {/* Display quantity input for each selected product */}
        {newOrderData.selectedProducts.map((productId) => (
          <TextField
            key={productId}
            fullWidth
            margin="normal"
            variant="outlined"
            label={`Quantity for ${
              products.find((p) => p.id === productId)?.name_en
            }`}
            value={productQuantities[productId] || ""}
            onChange={(e) => handleQuantityChange(productId, e.target.value)}
            error={!productQuantities[productId]}
            helperText={!productQuantities[productId] && "Required"}
          />
        ))}

        {!useUserPoints && (
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Promo Code</InputLabel>
            <Select
              value={newOrderData.promocode_id || ""}
              onChange={(e) => handleChange("promocode_id", e.target.value)}
              label="Select Promo Code"
            >
              {promoCodes.map((promo) => (
                <MenuItem key={promo.id} value={promo.id}>
                  {promo.code} - {promo.value}% off
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel>Is Paid *</InputLabel>
          <Select
            value={newOrderData.is_paid}
            onChange={(e) => handleChange("is_paid", e.target.value)}
            label="Select Is Paid"
          >
            <MenuItem value="1">Paid</MenuItem>
            <MenuItem value="0">Unpaid</MenuItem>
          </Select>
          {errors.is_paid && (
            <FormHelperText style={{ color: "red" }}>Required</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel>Status *</InputLabel>
          <Select
            value={newOrderData.status}
            onChange={(e) => handleChange("status", e.target.value)}
            label="Select Status"
          >
            <MenuItem value="1">Pending</MenuItem>
            <MenuItem value="2">Approved</MenuItem>
            <MenuItem value="3">Shipped</MenuItem>
            <MenuItem value="4">Received</MenuItem>
            <MenuItem value="5">Rejected</MenuItem>
            <MenuItem value="6">Returned Damaged</MenuItem>
            <MenuItem value="7">Returned</MenuItem>
            <MenuItem value="8">Cancel</MenuItem>
          </Select>
          {errors.status && (
            <FormHelperText style={{ color: "red" }}>Required</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth margin="normal" variant="outlined">
          <FormControlLabel
            control={
              <Switch
                checked={deliveryFees}
                onChange={() => setDeliveryFees(!deliveryFees)}
                color="success"
              />
            }
            label="10 SAR Delivery fees"
            labelPlacement="start"
            style={{ marginLeft: 0, marginRight: "auto" }}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{
            color: "#ffd700",
            borderColor: "#ffd700",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          style={{
            color: "#ffd700",
            borderColor: "#ffd700",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Add Order
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrderDialog;
