import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "state/api";
import "react-datepicker/dist/react-datepicker.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "react-js-loader";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import * as XLSX from "xlsx";
import Pagination from "components/pagination";
import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box } from "@mui/material";
import { Input } from "@trendmicro/react-form-control";
import "./orders.css";
import DatePicker from "react-datepicker";
import EditOrderDialog from "./editDialog";
import AddOrderDialog from "./addDialog";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Orders = () => {
  const navigate = useNavigate(); // Get navigate function
  // State variables
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [shippingAddresses, setshippingAddresses] = useState([]);
  const [products, setProducts] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(100);
  /// filers
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedShippingAddress, setSelectedShippingAddress] = useState("");
  const [selectedPromoCodeId, setSelectedPromoCodeId] = useState("");
  const [selectedIsPaid, setSelectedIsPaid] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [minTotalPrice, setMinTotalPrice] = useState("");
  const [maxTotalPrice, setMaxTotalPrice] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  ////////////////////////////////////////////////////////////
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  // Single Delete
  const [deletingOrderId, setDeletingOrderId] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // Multi Delete
  const [deletingOrderIds, setDeletingOrderIds] = useState([]);
  const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);
  // Edit order data Dialog
  const [selectedOrderData, setSelectedOrderData] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  // Add new order Dialog
  const [addNewOrderDialogOpen, setAddNewOrderDialogOpen] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await api.getOrders();
        setOrders(response.order);
        setUsers(response.users);
        setshippingAddresses(response.shippingAddress);
        setPromoCodes(response.promoCode);
        setProducts(response.product);
        setSelectAll(false);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [currentPage]);

  // Select all + Pagination

  const filteredOrders = orders.filter((order) => {
    const statusFilter = !selectedStatus || order.status === selectedStatus;
    const userFilter =
      selectedUserId === null
        ? order.user_id === null
        : !selectedUserId || order.user_id?.toString().includes(selectedUserId);
    const orderFilter =
      !selectedOrderId || order.id.toString().includes(selectedOrderId);
    const shippingAddressFilter =
      !selectedShippingAddress ||
      order.shipping_address_id.toString().includes(selectedShippingAddress);
    const promoCodeIdFilter =
      !selectedPromoCodeId ||
      (order.promocode_id &&
        order.promocode_id.toString().includes(selectedPromoCodeId));
    const isPaidFilter =
      !selectedIsPaid ||
      (order.is_paid === 1 ? "Paid" : "Unpaid").includes(selectedIsPaid);
    const totalPriceFilter =
      (!minTotalPrice || order.total_price >= minTotalPrice) &&
      (!maxTotalPrice || order.total_price <= maxTotalPrice);
    const fromDateFilter =
      !fromDate || new Date(order.created_at) >= new Date(fromDate);
    const toDateFilter =
      !toDate || new Date(order.created_at) <= new Date(toDate);
    const paymentMethodFilter =
      !selectedPaymentMethod || order.payment_method === selectedPaymentMethod;

    return (
      statusFilter &&
      userFilter &&
      orderFilter &&
      shippingAddressFilter &&
      promoCodeIdFilter &&
      isPaidFilter &&
      totalPriceFilter &&
      fromDateFilter &&
      toDateFilter &&
      paymentMethodFilter
    );
  });

  // Calculate the range of users to display based on pagination
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredOrders.length / ordersPerPage); i++) {
    pageNumbers.push(i);
  }

  // Handle checkbox change for individual users
  const handleCheckboxChange = (orderId) => {
    const updatedOrders = orders.map((order) =>
      order.id === orderId ? { ...order, selected: !order.selected } : order
    );
    setOrders(updatedOrders);
  };

  // Handle checkbox change for all users
  const handleSelectAll = () => {
    const updatedOrders = orders.map((order) =>
      currentOrders.some((currentOrder) => currentOrder.id === order.id)
        ? { ...order, selected: !selectAll }
        : order
    );
    setOrders(updatedOrders);
    setSelectAll(!selectAll);
  };

  // Format updated and created at data
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Africa/Cairo",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  /////////////////////////////////////////////////////////  Filters   ////////////////////////////////////////////////////////////////////

  const handleUserIdChange = (e) => {
    const value = e.target.value;

    if (value === "guest") {
      setSelectedUserId(null);
      setCurrentPage(1);
    } else {
      setSelectedUserId(value);
    }
  };

  const handleOrderIdChange = (e) => {
    setSelectedOrderId(e.target.value);
  };

  const handleShippingAddressChange = (e) => {
    setSelectedShippingAddress(e.target.value);
    setCurrentPage(1);
  };

  const handlePromoCodeIdChange = (e) => {
    setSelectedPromoCodeId(e.target.value);
    setCurrentPage(1);
  };

  const handleIsPaidChange = (e) => {
    setSelectedIsPaid(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setCurrentPage(1);
  };

  const handlePaymentMethodChange = (e) => {
    setSelectedPaymentMethod(e.target.value);
    setCurrentPage(1);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  // Alert
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ ...alert, open: false });
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle single delete
  const handleDeleteSingle = (orderId) => {
    setDeletingOrderId(orderId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirmationSingle = async () => {
    try {
      setLoading(true);
      setDeleteDialogOpen(false);
      await api.removeOrder(deletingOrderId);
      const response = await api.getOrders();
      setOrders(response.order);
      setAlert({
        open: true,
        message: "Order deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting order:", error);
      setAlert({
        open: true,
        message: "Failed to delete order. Please try again.",
        severity: "error",
      });
    } finally {
      setDeletingOrderId(null);
      setLoading(false);
    }
  };
  const handleDeleteCancelSingle = () => {
    setDeleteDialogOpen(false);
    setDeletingOrderId(null);
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle delete from select
  const handleDelete = () => {
    const selectedOrderIds = orders
      .filter((order) => order.selected)
      .map((order) => order.id);
    if (selectedOrderIds.length === 0) {
      console.log("No Order selected for deletion");
      return;
    }
    setDeletingOrderIds(selectedOrderIds);
    setDeleteConfirmationDialogOpen(true);
  };
  const handleDeleteConfirmation = async () => {
    try {
      setDeleteConfirmationDialogOpen(false);
      setLoading(true);
      await api.removeOrders({ orderIds: deletingOrderIds });
      const response = await api.getOrders();
      setOrders(response.order);
      setAlert({
        open: true,
        message: "Orders deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting orders:", error);
      setAlert({
        open: true,
        message: "Failed to delete orders. Please try again.",
        severity: "error",
      });
    } finally {
      setDeletingOrderIds(null);
      setLoading(false);
    }
  };
  const handleDeleteCancel = () => {
    setDeleteConfirmationDialogOpen(false);
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Handle reset filters / Export
  const handleResetFilters = () => {
    setSelectedStatus("");
    setSelectedOrderId("");
    setSelectedUserId("");
    setSelectedShippingAddress("");
    setSelectedPromoCodeId("");
    setSelectedIsPaid("");
    setMinTotalPrice("");
    setMaxTotalPrice("");
    setFromDate(null);
    setToDate(null);
    setCurrentPage(1);
  };
  const handleExport = () => {
    const selectedOrders = orders.filter((order) => order.selected);

    // Define a mapping for more readable column names
    const columnNamesMapping = {
      id: "Order ID",
      user: "User",
      shipping_address: "Shipping Address",
      subtotal: "Subtotal",
      shipping_fees: "Shipping Fees",
      tax: "Tax",
      promocode: "Promo Code",
      promocode_price: "Promo Code Price",
      points: "Points",
      points_price: "Points Price",
      total_price: "Total Price",
      is_paid: "Payment Status",
      payment_method: "Payment Method",
      status: "Order Status",
      created_at: "Created At",
      updated_at: "Updated At",
    };

    const excelData = selectedOrders.map((order) => {
      const user = users.find((u) => u.id === order.user_id);
      const shippingAddress = shippingAddresses.find(
        (sa) => sa.id === order.shipping_address_id
      );
      const promoCode = promoCodes.find((pc) => pc.id === order.promocode_id);

      // Use the mapping for more readable column names
      const mappedData = {
        id: order.id,
        user: user
          ? `${user.first_name} ${user.last_name} - ${user.phone}`
          : "N/A",
        shipping_address: shippingAddress
          ? `${shippingAddress.first_name} ${shippingAddress.last_name} - ${shippingAddress.address} - ${shippingAddress.phone}`
          : "N/A",
        subtotal: order.subtotal,
        shipping_fees: order.shipping_fees,
        tax: order.tax,
        promocode: promoCode
          ? `${promoCode.code} - ${promoCode.value}%`
          : "N/A",
        promocode_price: order.promocode_price,
        points: order.points,
        points_price: order.points_price,
        total_price: order.total_price,
        is_paid: order.is_paid === 1 ? "Paid" : "Unpaid",
        payment_method: order.payment_method,
        status: order.status,
        created_at: order.created_at,
        updated_at: order.updated_at,
      };

      // Map the column names for readability
      const mappedOrder = {};
      Object.keys(mappedData).forEach((key) => {
        mappedOrder[columnNamesMapping[key]] = mappedData[key];
      });

      return mappedOrder;
    });

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders");
    XLSX.writeFile(wb, "selected_orders.xlsx");
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Edit existing order
  const handleEditClick = (order) => {
    setSelectedOrderData(order);
    setEditDialogOpen(true);
  };
  const handleEditSubmit = async (editedData) => {
    try {
      setLoading(true);
      await api.updateOrder(editedData);
      const response = await api.getOrders();
      console.log("API response:", response);
      setOrders(response.order);

      setAlert({
        open: true,
        message: "Order updated successfully!",
        severity: "success",
      });
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error submitting edited data:", error);
      setAlert({
        open: true,
        message: "Failed to update order. Please try again.",
        severity: "error",
      });
    } finally {
      setSelectedOrderData(null);
      setLoading(false);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// Add new order
  const handleAddNewOrderClick = () => {
    setAddNewOrderDialogOpen(true);
  };

  const handleAddNewOrderSubmit = async (data) => {
    try {
      setLoading(true);
      await api.addOrder(data);
      const response = await api.getOrders();
      setOrders(response.order);

      setAlert({
        open: true,
        message: "Oder Added successfully!",
        severity: "success",
      });

      setAddNewOrderDialogOpen(false);
    } catch (error) {
      setAlert({
        open: true,
        message: "Failed to update order. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// Add Download recipt

  const handleReceiptDownload = (orderId) => {
    navigate(`/receipt/${orderId}`);
  };

  return (
    <div className="container">
      <div className="filter-section">
        <div className="filter-set">
          <FormControl className="filter-form-control">
            <InputLabel>Order ID</InputLabel>
            <Select value={selectedOrderId} onChange={handleOrderIdChange}>
              <MenuItem value="" disabled>
                Select Order ID
              </MenuItem>
              {orders.map((order) => (
                <MenuItem key={order.id} value={order.id}>
                  {order.id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="filter-form-control">
            <InputLabel>User</InputLabel>
            <Select value={selectedUserId || ""} onChange={handleUserIdChange}>
              <MenuItem value="" disabled>
                Select User ID
              </MenuItem>
              <MenuItem value="guest">Guest</MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.first_name} {user.last_name} - {user.phone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="filter-form-control">
            <InputLabel>Shipping Address</InputLabel>
            <Select
              value={selectedShippingAddress}
              onChange={handleShippingAddressChange}
            >
              <MenuItem value="" disabled>
                Select Shipping Address
              </MenuItem>
              {shippingAddresses.map((shippingAddress) => (
                <MenuItem key={shippingAddress.id} value={shippingAddress.id}>
                  {shippingAddress.first_name} {shippingAddress.last_name} -{" "}
                  {shippingAddress.address} - {shippingAddress.phone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="filter-form-control">
            <InputLabel>Promocode</InputLabel>
            <Select
              value={selectedPromoCodeId}
              onChange={handlePromoCodeIdChange}
            >
              <MenuItem value="" disabled>
                Select Promocode ID
              </MenuItem>
              {promoCodes.map((promoCode) => (
                <MenuItem key={promoCode.id} value={promoCode.id}>
                  {promoCode.code} - {promoCode.value}%
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="filter-form-control">
            <InputLabel>Payment</InputLabel>
            <Select value={selectedIsPaid} onChange={handleIsPaidChange}>
              <MenuItem disabled> Select payment status</MenuItem>
              <MenuItem value="Paid">Paid</MenuItem>
              <MenuItem value="Unpaid">Unpaid</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="filter-form-control">
            <InputLabel>Payment Method</InputLabel>
            <Select
              value={selectedPaymentMethod}
              onChange={handlePaymentMethodChange}
            >
              <MenuItem disabled> Select Payment Method</MenuItem>
              <MenuItem value="cod">Cash on delivery</MenuItem>
              <MenuItem value="visa">Visa</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="filter-set">
          <FormControl className="filter-form-control">
            <InputLabel>Status</InputLabel>
            <Select value={selectedStatus} onChange={handleStatusChange}>
              <MenuItem disabled> Select Status</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Shipped">Shipped</MenuItem>
              <MenuItem value="Received">Received</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
              <MenuItem value="Returned Damaged">Returned Damaged</MenuItem>
              <MenuItem value="Returned">Returned</MenuItem>
              <MenuItem value="Cancel">Cancel</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="filter-form-control">
            <Input
              type="number"
              value={minTotalPrice}
              onChange={(e) => setMinTotalPrice(e.target.value)}
              placeholder="Min Total Price"
            />
          </FormControl>

          <FormControl className="filter-form-control">
            <Input
              type="number"
              value={maxTotalPrice}
              onChange={(e) => setMaxTotalPrice(e.target.value)}
              placeholder="Max Total Price"
            />
          </FormControl>

          <FormControl className="filter-form-control">
            <DatePicker
              className="custom-datepicker"
              showIcon
              label="From Date"
              selected={fromDate}
              onChange={handleFromDateChange}
              dateFormat="dd/MM/yyyy"
              icon="fa fa-calendar"
              placeholderText="Select From Date"
            />
          </FormControl>

          <FormControl className="filter-form-control">
            <DatePicker
              className="custom-datepicker"
              showIcon
              label="To Date"
              selected={toDate}
              onChange={handleToDateChange}
              dateFormat="dd/MM/yyyy"
              icon="fa fa-calendar"
              placeholderText="Select To Date"
            />
          </FormControl>
        </div>
      </div>

      <div className="actions-section">
        <Box
          mb="1rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddNewOrderClick}
          >
            <AddIcon /> Add New Order
          </Button>
        </Box>

        <Box
          mb="1rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {/* <Button
                variant="contained"
                color="primary"
                onClick={handleAddNewOrderClick}
            >
                <AddIcon /> Add Bulk order
            </Button> */}
        </Box>
        <div className="actions-container">
          <Box
            mb="1rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              <DeleteIcon /> Delete
            </Button>
          </Box>

          <Box
            mb="1rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleExport}
            >
              <SaveAltIcon /> Export
            </Button>
          </Box>
          <Box
            mb="1rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleResetFilters}
            >
              <RefreshIcon /> Reset filters
            </Button>
          </Box>
        </div>
      </div>
      {loading ? (
        <div className="item">
          <Loader type="spinner-cub" bgColor="gold" color="gold" size={100} />
        </div>
      ) : (
        <table className="lookup-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectAll}
                />
              </th>
              <th>Order ID</th>
              <th>User</th>
              <th>Shipping Address</th>
              <th>Customer Phone</th>
              <th>Customer Email</th>
              <th>Subtotal</th>
              <th>Shipping Fees</th>
              <th>Payment Method</th>
              <th>Promocode</th>
              <th>Promocode Price</th>
              <th>Points</th>
              <th>Points Price</th>
              <th>Total Price</th>
              <th>Is Paid</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => (
              <tr key={order.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={order.selected || false}
                    onChange={() => handleCheckboxChange(order.id)}
                  />
                </td>
                <td>{order.id}</td>
                <td>
                  {users.map((user) => {
                    if (user.id === order.user_id) {
                      return (
                        <div key={user.id}>
                          {user.first_name} {user.last_name} - {user.phone}
                        </div>
                      );
                    }
                    return null;
                  })}
                </td>
                <td>
                  {shippingAddresses.map((shippingAddress) => {
                    if (shippingAddress.id === order.shipping_address_id) {
                      return (
                        <div key={shippingAddress.id}>
                          {shippingAddress.first_name}{" "}
                          {shippingAddress.last_name} -{" "}
                          {shippingAddress.address}
                        </div>
                      );
                    }
                    return null;
                  })}
                </td>
                <td>
                  {shippingAddresses.map((shippingAddress) => {
                    if (shippingAddress.id === order.shipping_address_id) {
                      return (
                        <div key={shippingAddress.id}>
                          {shippingAddress.phone}
                        </div>
                      );
                    }
                    return null;
                  })}
                </td>
                <td>
                  {shippingAddresses.map((shippingAddress) => {
                    if (shippingAddress.id === order.shipping_address_id) {
                      return (
                        <div key={shippingAddress.id}>
                          {shippingAddress.email}
                        </div>
                      );
                    }
                    return null;
                  })}
                </td>
                <td>{order.subtotal}</td>
                <td>{order.shipping_fees}</td>
                <td>{order.payment_method}</td>
                <td>
                  {promoCodes.map((promoCode) => {
                    if (promoCode.id === order.promocode_id) {
                      return (
                        <div key={promoCode.id}>
                          {promoCode.code} -{promoCode.value}%
                        </div>
                      );
                    }
                    return null;
                  })}
                </td>
                <td>{order.promocode_price}</td>
                <td>{order.points}</td>
                <td>{order.points_price}</td>
                <td>{order.total_price}</td>
                <td>{order.is_paid === 1 ? "Paid" : "Unpaid"}</td>
                <td>{order.status}</td>
                <td>{formatDate(order.created_at)}</td>
                <td>{formatDate(order.updated_at)}</td>
                <td>
                  {" "}
                  <IconButton
                    size="small"
                    onClick={() => handleEditClick(order)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteSingle(order.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleReceiptDownload(order.id)}
                  >
                    <FileDownloadIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Pagination
        currentPage={currentPage}
        paginate={paginate}
        totalItems={filteredOrders.length}
        itemsPerPage={ordersPerPage}
        prevButtonLabel="< Previous"
        nextButtonLabel="Next >"
        buttonColor="primary"
        activeButtonColor="contained"
        inactiveButtonColor="default"
        disabled={false}
      />

      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancelSingle}>
        <DialogContent className="dialog-content">
          <Typography style={{ fontSize: "1.2rem" }}>
            Are you sure you want to delete this order?
          </Typography>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            onClick={handleDeleteCancelSingle}
            variant="outlined"
            style={{
              color: "#ffd700",
              borderColor: "#ffd700",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirmationSingle}
            variant="outlined"
            style={{
              color: "#ffd700",
              borderColor: "#ffd700",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteConfirmationDialogOpen}
        onClose={handleDeleteCancel}
      >
        <DialogContent className="dialog-content">
          <Typography style={{ fontSize: "1.2rem" }}>
            Are you sure you want to delete the selected orders?
          </Typography>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            onClick={handleDeleteCancel}
            variant="outlined"
            style={{
              color: "#ffd700",
              borderColor: "#ffd700",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirmation}
            variant="outlined"
            style={{
              color: "#ffd700",
              borderColor: "#ffd700",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert.open}
        autoHideDuration={1000}
        onClose={handleCloseAlert}
      >
        <MuiAlert
          onClose={handleCloseAlert}
          severity={alert.severity}
          sx={{ width: "100%", fontSize: "1.5rem" }}
        >
          {alert.message}
        </MuiAlert>
      </Snackbar>

      <EditOrderDialog
        isOpen={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        orderData={selectedOrderData}
        onSubmit={handleEditSubmit}
      />

      <AddOrderDialog
        isOpen={addNewOrderDialogOpen}
        onClose={() => setAddNewOrderDialogOpen(false)}
        onSubmit={handleAddNewOrderSubmit}
        users={users}
        shippingAddresses={shippingAddresses}
        promoCodes={promoCodes}
        products={products}
      />
    </div>
  );
};

export default Orders;
