import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./orders.css";

const EditOrderDialog = ({ isOpen, onClose, orderData, onSubmit }) => {
  const [editedData, setEditedData] = useState({
    id: "",
    user_id: "",
    shipping_address_id: "",
    subtotal: "",
    shipping_fees: "",
    tax: "",
    promocode_id: "",
    promocode_price: "",
    points: "",
    points_price: "",
    total_price: "",
    is_paid: "0",
    status: "",
    products: [],
    created_at: "",
    updated_at: "",
    // Add more fields as needed
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    const defaultValues = {
      id: "",
      user_id: "",
      shipping_address_id: "",
      subtotal: "",
      shipping_fees: "",
      tax: "",
      promocode_id: "",
      promocode_price: "",
      points: "",
      points_price: "",
      total_price: "",
      is_paid: "0",
      status: "",
      products: [],
      created_at: "",
      updated_at: "",
    };

    setEditedData(orderData || defaultValues);
  }, [orderData]);

  const handleChange = (name, value) => {
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateFields = () => {
    const errors = {};

    if (
      String(editedData.is_paid) !== "0" &&
      String(editedData.is_paid) !== "1"
    ) {
      errors.is_paid = "Please select a valid payment status";
    }

    if (!editedData.status) {
      errors.status = "Status is required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const renderAsterisk = (fieldName) => {
    return validationErrors[fieldName] ? "*" : "";
  };

  const handleSubmit = () => {
    if (validateFields()) {
      onSubmit(editedData);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent dividers>
        {editedData && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Basic Information
            </Typography>

            <TextField
              label={`Order ID ${renderAsterisk("id")}`}
              value={editedData.id}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`User ID ${renderAsterisk("user_id")}`}
              value={editedData.user_id}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`Shipping Address ${renderAsterisk(
                "shipping_address_id"
              )}`}
              value={editedData.shipping_address_id}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />

            <TextField
              label={`Subtotal ${renderAsterisk("subtotal")}`}
              value={editedData.subtotal || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`Shipping Fees ${renderAsterisk("shipping_fees")}`}
              value={editedData.shipping_fees || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`Tax ${renderAsterisk("tax")}`}
              value={editedData.tax || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`Promocode ID ${renderAsterisk("promocode_id")}`}
              value={editedData.promocode_id || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`Promocode Price ${renderAsterisk("promocode_price")}`}
              value={editedData.promocode_price || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`Points ${renderAsterisk("points")}`}
              value={editedData.points || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`Points Price ${renderAsterisk("points_price")}`}
              value={editedData.points_price || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />
            <TextField
              label={`Total Price ${renderAsterisk("total_price")}`}
              value={editedData.total_price || ""}
              fullWidth
              margin="normal"
              variant="outlined"
              InputProps={{ readOnly: true }}
            />

            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
              error={Boolean(validationErrors.is_paid)}
            >
              <InputLabel>Is Paid *</InputLabel>
              <Select
                value={editedData.is_paid}
                onChange={(e) => handleChange("is_paid", e.target.value)}
                label="Select Is Paid"
              >
                <MenuItem value="1">Paid</MenuItem>
                <MenuItem value="0">Unpaid</MenuItem>
              </Select>
              <Typography variant="body2" color="error">
                {validationErrors.is_paid}
              </Typography>
            </FormControl>

            <FormControl
              fullWidth
              margin="normal"
              variant="outlined"
              error={Boolean(validationErrors.status)}
            >
              <InputLabel>Status *</InputLabel>
              <Select
                value={editedData.status}
                onChange={(e) => handleChange("status", e.target.value)}
                label="Select Status"
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Shipped">Shipped</MenuItem>
                <MenuItem value="Received">Received</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Returned Damaged">Returned Damaged</MenuItem>
                <MenuItem value="Returned">Returned</MenuItem>
                <MenuItem value="Cancel">Cancel</MenuItem>
              </Select>
              <Typography variant="body2" color="error">
                {validationErrors.status}
              </Typography>
            </FormControl>

            <Typography variant="h3" gutterBottom>
              Products
            </Typography>
            {editedData.products.map((product, index) => (
              // <Box key={index}>
              //   <Typography >{product.name_en}</Typography>
              //   <Typography>Price: {product.price} SAR</Typography>
              //   <Typography>Quantity: {product.pivot.quantity}</Typography>
              //   {/* You can add more details or media if needed */}
              // </Box>
              <div key={index} className="orders-products">
                <span>{product.name_en}</span>
                <span>Single Product Price: {product.price} SAR</span>
                <span>Quantity: {product.pivot.quantity}</span>
                <img
                  style={{ marginBottom: "20px" }}
                  src={product.medias[0].image_url}
                  alt="product.medias.image_url"
                />
              </div>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          style={{ color: "#ffd700", fontSize: "16px", fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          style={{ color: "#ffd700", fontSize: "16px", fontWeight: "bold" }}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderDialog;
